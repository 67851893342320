import Aos from 'aos' 
import "aos/dist/aos.css";
import './App.css';
import { motion } from 'framer-motion';
import traped from './trap.png'
import trapedb from './trapb.png'
import zenter from './zent.png'
import goosy from './cloth.png'
import yat1 from './yat1.mp4'
import jan from './Jan.png'
import cler from './moncler.png'
import mc from './mc.png'
import red1 from './red1.png'
import fronty from './fronty.png'
import villain from './villain.png'
import goose from './goose.png'
import sans from './sans.png'
import ice from './ice.png'
import hood from './hood.png'
import {Link} from 'react-scroll'

import vont from './vont.png'
import x from './x.png'
import truce from './truce.png'
import red from './red.jpg'
import black from './black.jpg'
import blue from './blue.png'


import white from './white.jpg'
import Typewriter from 'typewriter-effect'
import { useEffect, useState } from 'react';
import Rating from '@mui/material/Rating';


function Home () {
  const [goosew, SetGooseW] = useState(false)
  const [gooser,SetGooseR] = useState(false)
  const [gooseg, SetGooseG] = useState(true)
  const [gooseb, SetGooseB] = useState(false)
  const [goosebl, SetGooseBL] = useState(false)
  const [traps, SetTraps] = useState(false)


  const [buy, setBuy] = useState(false)
  const [link, setLink] = useState(false)
  const [link1, setLink1] = useState(false)
  const [link2, setLink2] = useState(false)
  const [link3, setLink3] = useState(false)
  const [link4, setLink4] = useState(false)
  const [link5, setLink5] = useState(false)
  const [link6, setLink6] = useState(false)

  const [intro, setIntro] = useState(true)
  const [animate, setAnimate] = useState(true)
  const [animate1, setAnimate1] = useState(false)
  const [count, setCount] = useState(-5)
  const [page, setPage] = useState(false)
  const [first, setFirst] = useState(true)
  const [first1, setFirst1] = useState(false)
  const [finale, setFinale] = useState(false)

  const [goosebl1, SetGooseBL1] = useState(true)

  const [secound, setSecound] = useState(false)
  const [third, setThird] = useState(false)
  const iced = 'https://buy.stripe.com/14k7uYaPFgWlbkc14c'
  const externalUrl = 'https://buy.stripe.com/6oE5mQcXN7lLdsk4gi'; 
  const trap = 'https://buy.stripe.com/28og1ue1R49zbkc8wz'; 
  const jt = ' https://buy.stripe.com/8wM7uY9LBbC1cog6ou';
  const fliz = 'https://buy.stripe.com/eVa16Af5VdK9bkcdQU'; 
const vont1 = 'https://buy.stripe.com/bIY8z25vl35vbkcfZ3'
const banz1 = 'https://buy.stripe.com/7sI16A5vlcG54VOaEL'
function icePay () {
  window.location.href = iced;
}
  function goosePay () {
    window.location.href = externalUrl;
}
function banzPay () {
  window.location.href = banz1;
}
function vontPay () {
  window.location.href = vont1;
}
function jtPay () {
  window.location.href = jt;
}

function trapPay () {
  window.location.href = trap;
}
function flizPay () {
  window.location.href = fliz;
}
   useEffect(() => {
    const interval = setInterval(() => {
      if (count < 6) {
        setCount(count + 1);
      }
    }, 1100);

    return () => clearInterval(interval);
  }, [count]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (page) {
        setPage(false);
      }
    }, 2700);

    return () => clearInterval(interval);
  }, [page]);

  useEffect(() => {
    setInterval(() => {
      setAnimate(!animate)
    }, 1000)
  },[])


  
  useEffect(() => {
    setInterval(() => {
      setIntro(false)
    }, 5500)
  },[])


  
  useEffect(() => {
    setInterval(() => {
      setAnimate1(animate1)
    }, 1500)
  },[])
  
  
  useEffect(() => {
    const interval = setInterval(() => {
      if (first1) {
        setFirst1(false);
      }
    }, 2700);

    return () => clearInterval(interval);
  }, [first1]);

  useEffect(() => {
    setTimeout(() => {
      Aos.init({ duration: 2500 });
    }, 1000); // Adjust the delay as needed
  }, []);
  




  useEffect(() => {
    const interval = setInterval(() => {
      if (buy) {
        setBuy(false);
      }
    }, 2700);

    return () => clearInterval(interval);
  }, [buy]);








  return (
    <div className="App">


{intro?(
<div>
  <div style = {{backgroundColor:'black', height:'100vh', width:'100vw', zIndex:1,position:'absolute',top:'0vh',left:'0vw'}} />
  <motion.i initial = {{opacity:0}} animate = {{opacity:1, }} transition = {{duration:1.2,delay:2}} className="king fa-solid fa-crown"/>
<motion.img src= {jan} initial = {{opacity:0}} animate = {{opacity:1, }} transition = {{duration:1.2,delay:2}} className = 'zents' />
  </div>
):(
<div>
  
<motion.img initial = {{opacity:0}} animate = {{opacity:1}} transition = {{duration:2, delay:7}} src = {zenter} className = 'lezent' />
<div style = {{display:third? 'none' : 'block'}}>
<div className = {!secound? 'log1' : 'log11'}  />
   <div className = {!secound? 'log2' : 'log22'}  />
 
   <div className = {!secound? 'log3' : 'log33'} />
</div>

  <motion.div initial = {{opacity:0, y:-200}} animate = {{opacity:1, y:0}} transition = {{duration:2, delay:7.6}} className = {first? 'childa':secound? 'child1' : 'child111'} style = {{ background:first && !third && !secound? 'whitesmoke': 'transparent'}}/>
  <motion.div initial = {{opacity:0, y:-200}} animate = {{opacity:1, y:0}} transition = {{duration:2, delay:7.6}} className = {first? 'childb':secound? 'child2' : 'child222'} style = {{ background:!first && !third && secound? 'whitesmoke': 'transparent', }}/>
  <motion.div initial = {{opacity:0, y:-200}} animate = {{opacity:1, y:0}} transition = {{duration:2, delay:7.6}} className = {first? 'childc': secound? 'child3' : 'child333'} style = {{background:!first && third && !secound?'whitesmoke': 'transparent'}}/>

  <motion.div style = {{height:'107vh', width:'103vw', objectFit:'fill', position:'fixed', left:'-1vw', backgroundColor:'rgb(26, 24, 24)', zIndex:4,top:'-5vh'}} initial = {{x:-2300}} animate = {{x: buy? -5: -2300}} transition = {{duration:1, type:'tween'}}  />
 
  <motion.div whileHover = {{cursor:'pointer'}} onClick = {() => {setPage(true); setSecound(true); setFirst(false); setThird(false)}} className = {secound? 'redd': first? 'reddt' : 'redzz'} style = {{backgroundColor:'red', height:'35px', width:'35px', zIndex:13,opacity:0,display:first? 'block' :third? 'block':'none'}}/>
   <motion.div whileHover = {{cursor:'pointer'}} onClick = {() => { setSecound(false);setPage(true); setFirst(true); setThird(false); setFirst1(true)}} className = {secound? 'reddd': first? 'redddt' : 'redz'} style = {{backgroundColor:'red', height:'35px', width:'35px', zIndex:13, opacity:0, display:secound? 'block' : third? 'block' : 'none'}}/>
   <motion.div whileHover = {{cursor:'pointer'}} onClick = {() => { setThird(true); setFirst(false); setSecound(false); setPage(true)}} className = {secound? 'redddd': first? 'reddddt' : 'redzzz'} style = {{backgroundColor:'red', height:'35px', width:'35px',zIndex:13,opacity:0,   display: secound? 'block' : first? 'block' : 'none' }}/>
   
   <motion.div initial = {{y:-1500}} animate = {{y: page? -5: -1500}} transition = {{duration:1, type:'tween'}}  style = {{height:'103vh', width:'103vw', objectFit:'fill', position:'fixed', left:'-1vw', backgroundColor:'rgb(26, 24, 24)', zIndex:4}}/>

   {third? (
   
   <div>
    <motion.h1 initial = {{opacity:0}} animate = {{ opacity:third? 1:0}} transition = {{delay:4, duration:1}} className = 'qa'>Q&A</motion.h1> 
    <motion.h2 initial = {{opacity:0, x:-40}} animate = {{opacity: third? 1:0, x: third? 0:-40}} transition = {{type:'tween', duration:1.4, delay:6}} className = 'lefto'>Who Made This Amazing Website?</motion.h2>
    <div className = 'pole-a' />
    <div className = 'pole-b' />
    <div className = 'pole-c'  />

    <motion.h2 initial = {{opacity:0, x:40}} animate = {{opacity:third? 1:0, x: third? 10:40}} transition = {{type:'tween', duration:1.4, delay:7.5}} className = 'righto'><span style = {{color:'aquamarine'}}>Rayan Subeih,</span>  Contact Me To Make You A Website. Instagram: rayan_subeih</motion.h2>

    <motion.h2 initial = {{opacity:0, x:-40}} animate = {{opacity: third? 1:0, x: third? 0:-40}} transition = {{type:'tween', duration:1.4, delay:9}} className = 'leftoo'>Are New Zentyrs Coming Out Soon?</motion.h2>
    <motion.h2 initial = {{opacity:0, x:40}} animate = {{opacity:third? 1:0, x: third? 0:40}} transition = {{type:'tween', duration:1.4, delay:10.5}} className = 'rightoo'><span style = {{color:'orange',}}>New Zentyr,</span> Unparrallaled, Unmatched & Luxurious Jackets, Perfumes & Garments Coming Soon!! </motion.h2>
    
    <motion.h2 initial = {{opacity:0, x:-40}} animate = {{opacity: third? 1:0, x: third? 0:-40}} transition = {{type:'tween', duration:1.4, delay:12}} className = 'leftooo'>Where Can I Contact Zentyr?</motion.h2>
    <motion.h2 initial = {{opacity:0, x:40}} animate = {{opacity:third? 1:0, x: third? 0:40}} transition = {{type:'tween', duration:1.4, delay:13.5}} className = 'rightooo'><span style = {{color:'red'}}>Reach Out</span> Through Our Instagram: Zentyr, Or Email rayanbrogo@gmail.com </motion.h2>
    <div style = {{position:'absolute', top:'85vh', width:'100vw', height:'15vh', backgroundColor:'rgb(16, 16, 16)',left:'0vw'}}>
<motion.img  src = {zenter} className = 'emo' style = {{ position:'absolute', bottom:'1.4vh',left:'4vw',zIndex:3}}/>
</div>
    
    <div/>
   
   
   
   </div>)
   
   
   
   
   : (<div>{link6? (<motion.div initial = {{display:'none'}} animate = {{display:link6? 'block' : 'none'}} transition = {{delay:2.4}}>
</motion.div>) : (
    <div>

   {link5? (<div><motion.div initial = {{display:'none'}} animate = {{display:link5? 'block' : 'none'}} transition = {{delay:2.4}}>



<div className = 'money'>
   <div className = 'mannyy'>
  <img className = 'pancakee' style={{display:traps? 'none' : 'block'  }} src = {x}/>
  </div>
  
  <div className = 'e'><motion.i  whileHover={{cursor:'pointer'}} onClick = {() => {setLink5(false)}} style={{zIndex:3, color:'gold', fontSize:'18px', position:'absolute',left:'48vw', top:'11vh', background:'rgb(46, 44, 44)', borderRadius:'100%',padding:'10px'}} class="fa-solid fa-arrow-left"/></div>
  <h5  className = 'bandss '>Zentyr Banz JT</h5>
  <div className = 'e' style = {{height:'1px', width:'240px', border:'gold dotted 1px ',right:'34vw', top:'30vh',position:'absolute' }}/>
  <p className = 'graphh' >The Epitome of Luxury and Elegance. This Zentyr coat boasts a timeless design with a rich black exterior, complemented by subtle red or yellow accents.</p>
  <div className='e' style = {{ width:'240px', border:'gold solid 1px ',right:'34vw', top:'43.5vh',position:'absolute' }}/>
  <h5 className='e'  style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'25px', fontWeight:'100', position:'absolute',right:'47vw', top:'40vh'}} >Color</h5>
  <motion.button className='e'  whileHover = {{cursor:gooseg? 'default': 'pointer'}}  style  = {{background:'black', padding:'20px', borderRadius:'100%',position:'absolute',right:'47vw', top:'50vh',border:'solid goldenrod 1.5px' }}/>
  
  <motion.button className='e'  whileHover = {{cursor:gooseg? 'default': 'pointer'}}  style  = {{background:'lightyellow', padding:'20px', borderRadius:'100%',position:'absolute',right:'41.5vw', top:'50vh',border:'solid goldenrod 1.5px' }}/>
<div className ='e'>    <motion.button whileHover = {{cursor: 'pointer'}}  onClick = {banzPay} className = 'shop'  style = {{height:'60px',width:'190px',background:'transparent',border:'solid 2px gold',position:'absolute', top:'60vh',right:'37vw',zIndex:2, fontFamily:'Oswald',fontSize:'22px',color:'white'}}>Purchase</motion.button>
</div>
   <div className = 'e'>
    <motion.div whileHover = {{cursor:'pointer'}} onClick = {() => {SetTraps(!traps)}} style={{position:'absolute',top:'91vh', left:'2vw',height:'185px', width:'230px', zIndex:3, border:'double 3px black', borderRadius:'5px',backgroundColor:'rgb(26, 26, 25)'}}>
    <img style={{ top:traps? '-68.7vh' : '0vh', left:traps? '0vw' : '0vw',height:traps? '600px' : '190px', width:traps? '605px' : '230px', zIndex:3, backgroundColor:'transparent', position:'relative',}} src = {truce}/>
  </motion.div>


</div>
</div>







<div className = 'dave'>
  <div className = 'manny'>
  <img className = 'pancake' style={{display:traps? 'none' : 'block'  }} src = {x}/>
  </div>
  
  <div className = 'e'><motion.i  whileHover={{cursor:'pointer'}} onClick = {() => {setLink5(false)}} style={{zIndex:3, color:'gold', fontSize:'18px', position:'absolute',left:'48vw', top:'11vh', background:'rgb(46, 44, 44)', borderRadius:'100%',padding:'10px'}} class="fa-solid fa-arrow-left"/></div>
 <div className = 'eq'>  <motion.i  whileHover={{cursor:'pointer'}} onClick = {() => {setLink5(false)}} style={{zIndex:3, color:'gold', fontSize:'18px',background:'rgb(46, 44, 44)', borderRadius:'100%',padding:'10px'}} class="fa-solid fa-arrow-left"/>
</div>
<div className = 'eqr'>  <motion.i  whileHover={{cursor:'pointer'}} onClick = {() => {setLink5(false)}} style={{zIndex:3, color:'gold', fontSize:'24px',background:'rgb(46, 44, 44)', borderRadius:'100%',padding:'20px'}} class="fa-solid fa-arrow-left"/>
</div>



  <h5  className = 'bands '>Zentyr Banz JT</h5>
  <div className = 'e' style = {{height:'1px', width:'240px', border:'gold dotted 1px ',right:'34vw', top:'30vh',position:'absolute' }}/>
  <p className = 'graph' >The Epitome of Luxury and Elegance. This Zentyr coat boasts a timeless design with a rich black exterior, complemented by subtle red or yellow accents.</p>
  <div className='e' style = {{ width:'240px', border:'gold solid 1px ',right:'34vw', top:'41vh',position:'absolute' }}/>
  <h5 className='e'  style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'25px', fontWeight:'100', position:'absolute',right:'44vw', top:'38vh'}} >Color</h5>
  <motion.button className='e'  whileHover = {{cursor:gooseg? 'default': 'pointer'}}  style  = {{background:'black', padding:'20px', borderRadius:'100%',position:'absolute',right:'44.5vw', top:'50vh',border:'solid goldenrod 1.5px' }}/>
  
  <motion.button className='e'  whileHover = {{cursor:gooseg? 'default': 'pointer'}}  style  = {{background:'lightyellow', padding:'20px', borderRadius:'100%',position:'absolute',right:'40.5vw', top:'50vh',border:'solid goldenrod 1.5px' }}/>
<div className ='e'>    <motion.button whileHover = {{cursor: 'pointer'}}  onClick = {banzPay} className = 'shop'  style = {{height:'60px',width:'190px',background:'transparent',border:'solid 2px gold',position:'absolute', top:'60vh',right:'37vw',zIndex:2, fontFamily:'Oswald',fontSize:'22px',color:'white'}}>Purchase</motion.button>
</div>
<div className = 'paz'>
    <motion.button whileHover = {{cursor: 'pointer'}}  onClick = {banzPay} className = 'shop'  style = {{height:'60px',width:'190px',background:'transparent',border:'solid 2px gold',zIndex:2, fontFamily:'Oswald',fontSize:'22px',color:'white'}}>Purchase</motion.button>
    </div>
    <div className = 'pazt'>
    <motion.button whileHover = {{cursor: 'pointer'}}  onClick = {banzPay} className = 'shop'  style = {{height:'66px',width:'250px',background:'transparent',border:'solid 2px gold',zIndex:2, fontFamily:'Oswald',fontSize:'27px',color:'white'}}>Purchase</motion.button>
    </div>
    <div className = 'paztz'>
    <motion.button whileHover = {{cursor: 'pointer'}}  onClick = {banzPay} className = 'shop'  style = {{height:'70px',width:'280px',background:'transparent',border:'solid 2px gold',zIndex:2, fontFamily:'Oswald',fontSize:'33px',color:'white'}}>Purchase</motion.button>
    </div>
    <motion.div className = 'undadog' >
    <img style={{ top: '0vh', left:'0vw',height: '190px', width:'230px', zIndex:3, backgroundColor:'transparent', position:'relative',}} src = {truce}/>
  </motion.div>



   <div className = 'e'>
    <motion.div whileHover = {{cursor:'pointer'}} onClick = {() => {SetTraps(!traps)}} style={{position:'absolute',top:'80vh', left:'12vw',height:'185px', width:'230px', zIndex:3, border:'double 3px black', borderRadius:'5px',backgroundColor:'rgb(26, 26, 25)'}}>
    <img style={{ top:traps? '-68.7vh' : '0vh', left:traps? '0vw' : '0vw',height:traps? '600px' : '190px', width:traps? '605px' : '230px', zIndex:3, backgroundColor:'transparent', position:'relative',}} src = {truce}/>
  </motion.div>
   
  <motion.div whileHover = {{cursor:'pointer'}} onClick = {() => {SetTraps(!traps)}} style={{position:'absolute',top:'80vh', left:'12vw',height:'185px', width:'230px', zIndex:3, border:'double 3px black', borderRadius:'5px',backgroundColor:'rgb(26, 26, 25)', display:traps? 'block' : 'none'}}>
    <img style={{ top: '0vh', left: '0vw',height: '190px', width: '230px', zIndex:3, backgroundColor:'transparent', position:'relative',bottom:'70px'}} src = {truce}/>
  </motion.div>
  </div>
  </div>
</motion.div></div>) : (
    <div>

   {link4? (<div><motion.div initial = {{display:'none'}} animate = {{display:link4? 'block' : 'none'}} transition = {{delay:2.4}}>


<div className = 'money'>
   <div className = 'bidenn' >
  <img className = 'snifff' style={{ display:traps? 'none' : 'block'}} src = {vont}/>
  </div>

  <div className = 'e'>
  <motion.i whileHover={{cursor:'pointer'}} onClick = {() => {setLink4(false)}} style={{zIndex:3, color:'gold', fontSize:'18px', position:'absolute',left:'48vw', top:'11vh', background:'rgb(46, 44, 44)', borderRadius:'100%',padding:'10px'}} class="fa-solid fa-arrow-left"/>
  </div> 
  <h5 className = 'sandd' >Zentyr Vont JT</h5>
  <div className = 'e' style = {{height:'1px', width:'240px', border:'gold dotted 1px ',right:'34vw', top:'30vh',position:'absolute' }}/>
  <p className = 'sandyy'  >The Most Luxurious & Desired. Blackout Dark Hoodman In Design, Red-White Velvet In Design Zipper & Swaggering Blacked-Out Puffed Hoodie.</p>
  <div className = 'e' style = {{ width:'240px', border:'gold solid 1px ',right:'34vw', top:'43vh',position:'absolute' }}/>
  <h5  className = 'e' style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'25px', fontWeight:'100', position:'absolute',right:'47vw', top:'40vh'}} >Color</h5>
  <motion.button className = 'e' whileHover = {{cursor:gooseg? 'default': 'pointer'}}  style  = {{background:'black', padding:'20px', borderRadius:'100%',position:'absolute',right:'47.4vw', top:'51vh',border:'solid goldenrod 1.5px' }}/>
  

  <motion.div whileHover = {{cursor:'pointer'}} onClick = {() => {SetTraps(!traps)}} style={{position:'absolute',top:'91vh', left:'2vw',height:'185px', width:'230px', zIndex:3, border:'double 3px black', borderRadius:'5px',backgroundColor:'rgb(26, 26, 25)'}}>
    <img style={{ top:traps? '-59vh' : '2vh', left:traps? '3vw' : '0vw',height:traps? '450px' : '160px', width:traps? '505px' : '160px', zIndex:3, backgroundColor:'transparent', position:'relative',}} src = {villain}/>
  </motion.div>

<div className = 'e'>    
<motion.button whileHover = {{cursor: 'pointer'}}  onClick = {vontPay} className = 'shop'  style = {{height:'60px',width:'190px',background:'transparent',border:'solid 2px gold',position:'absolute', top:'60vh',right:'37vw',zIndex:2, fontFamily:'Oswald',fontSize:'22px',color:'white'}}>Purchase</motion.button>
</div>
</div>







    <div className = 'dave'>
  <div className = 'biden' >
  <img className = 'sniff' style={{ display:traps? 'none' : 'block'}} src = {vont}/>
  </div>

  <div className = 'e'>
  <motion.i whileHover={{cursor:'pointer'}} onClick = {() => {setLink4(false)}} style={{zIndex:3, color:'gold', fontSize:'18px', position:'absolute',left:'48vw', top:'11vh', background:'rgb(46, 44, 44)', borderRadius:'100%',padding:'10px'}} class="fa-solid fa-arrow-left"/>
  </div> 
  <div className = 'he'>
  <motion.i whileHover={{cursor:'pointer'}} onClick = {() => {setLink4(false)}} style={{zIndex:3, color:'gold', fontSize:'18px', background:'rgb(46, 44, 44)', borderRadius:'100%',padding:'10px'}} class="fa-solid fa-arrow-left"/>
  </div>
  <div className = 'she'>
  <motion.i whileHover={{cursor:'pointer'}} onClick = {() => {setLink4(false)}} style={{zIndex:3, color:'gold', fontSize:'23px', background:'rgb(46, 44, 44)', borderRadius:'100%',padding:'20px'}} class="fa-solid fa-arrow-left"/>
  </div>

  
  <h5 className = 'sand' >Zentyr Vont JT</h5>
  <div className = 'e' style = {{height:'1px', width:'240px', border:'gold dotted 1px ',right:'34vw', top:'30vh',position:'absolute' }}/>
  <p className = 'sandy'  >The Most Luxurious & Desired. Blackout Dark Hoodman In Design, Red-White Velvet In Design Zipper & Swaggering Blacked-Out Puffed Hoodie.</p>
  <div className = 'e' style = {{ width:'240px', border:'gold solid 1px ',right:'34vw', top:'41vh',position:'absolute' }}/>
  <h5  className = 'e' style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'25px', fontWeight:'100', position:'absolute',right:'44vw', top:'38vh'}} >Color</h5>
  <motion.button className = 'e' whileHover = {{cursor:gooseg? 'default': 'pointer'}}  style  = {{background:'black', padding:'20px', borderRadius:'100%',position:'absolute',right:'44.5vw', top:'50vh',border:'solid goldenrod 1.5px' }}/>
  

<div className = 'e'>    
<motion.button whileHover = {{cursor: 'pointer'}}  onClick = {vontPay} className = 'shop'  style = {{height:'60px',width:'190px',background:'transparent',border:'solid 2px gold',position:'absolute', top:'60vh',right:'37vw',zIndex:2, fontFamily:'Oswald',fontSize:'22px',color:'white'}}>Purchase</motion.button>
</div>
<div className = 'ppe'>    
<motion.button whileHover = {{cursor: 'pointer'}}  onClick = {vontPay} className = 'shop'  style = {{height:'60px',width:'190px',background:'transparent',border:'solid 2px gold',position:'absolute', top:'60vh',right:'37vw',zIndex:2, fontFamily:'Oswald',fontSize:'22px',color:'white'}}>Purchase</motion.button>
</div>

<div className = 'ppet'>    
<motion.button whileHover = {{cursor: 'pointer'}}  onClick = {vontPay} className = 'shop'  style = {{height:'64px',width:'230px',background:'transparent',border:'solid 2px gold',position:'absolute', top:'60vh',right:'37vw',zIndex:2, fontFamily:'Oswald',fontSize:'28px',color:'white'}}>Purchase</motion.button>
</div>

<motion.div whileHover = {{cursor:'pointer'}} className = 'bttom'>
    <img style={{ top: '2vh', left: '0vw',height: '160px', width: '160px', zIndex:3, backgroundColor:'transparent', position:'relative',}} src = {villain}/>
  </motion.div>
  <motion.div whileHover = {{cursor:'pointer'}} className = 'bttomz'>
    <img style={{ top: '1vh', left: '0vw',height: '170px', width: '180px', zIndex:3, backgroundColor:'transparent', position:'relative',}} src = {villain}/>
  </motion.div>
  
   <div className = 'e'>
    <motion.div whileHover = {{cursor:'pointer'}} onClick = {() => {SetTraps(!traps)}} style={{position:'absolute',top:'80vh', left:'12vw',height:'185px', width:'230px', zIndex:3, border:'double 3px black', borderRadius:'5px',backgroundColor:'rgb(26, 26, 25)'}}>
    <img style={{ top:traps? '-63vh' : '2vh', left:traps? '3vw' : '0vw',height:traps? '450px' : '160px', width:traps? '505px' : '160px', zIndex:3, backgroundColor:'transparent', position:'relative',}} src = {villain}/>
  </motion.div>
   
  <motion.div whileHover = {{cursor:'pointer'}} onClick = {() => {SetTraps(!traps)}} style={{position:'absolute',top:'80vh', left:'12vw',height:'185px', width:'230px', zIndex:3, border:'double 3px black', borderRadius:'5px',backgroundColor:'rgb(26, 26, 25)', display:traps? 'block' : 'none'}}>
    <img style={{ top: '2vh', left: '0vw',height: '160px', width: '160px', zIndex:3, backgroundColor:'transparent', position:'relative',}} src = {villain}/>
  </motion.div>
  </div>
  </div>
</motion.div></div>) : (
    <div>

   {link3? (<div><motion.div initial = {{display:'none'}} animate = {{display:link3? 'block' : 'none'}} transition = {{delay:2.4}}>


   <div className = 'money'>
   <div className = 'kasss'>
  <img className = 'bloodd' style={{display:traps? 'none':'block' }} src = {hood}/>
  </div>
  <div className = 'e'>
  <motion.i whileHover={{cursor:'pointer'}} onClick = {() => {setLink3(false)}} style={{zIndex:3, color:'gold', fontSize:'18px', position:'absolute',left:'48vw', top:'11vh', background:'rgb(46, 44, 44)', borderRadius:'100%',padding:'10px'}} class="fa-solid fa-arrow-left"/>
  </div>
  <h5 className = 'fluzz'  >Zentyr Fliz JT</h5>
  <div className = 'e' style = {{height:'1px', width:'240px', border:'gold dotted 1px ',right:'34vw', top:'30vh',position:'absolute' }}/>
  <p className = 'hodmann'>It doesn't get more hoodman than this. A Blackout layout, comfy puffy jacket and a London style Hoodie, A Must.</p>
  <div style = {{ width:'240px', border:'gold solid 1px ',right:'34vw', top:'41vh',position:'absolute' }}/>
  <h5  style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'25px', fontWeight:'100', position:'absolute',right:'47vw', top:'40vh'}} >Color</h5>
  <motion.button whileHover = {{cursor:gooseg? 'default': 'pointer'}} className = 'e'  style  = {{background:'black', padding:'20px', borderRadius:'100%',position:'absolute',right:'47vw', top:'50vh',border:'solid goldenrod 1.5px' }}/>
  

<div className = 'e'>    <motion.button whileHover = {{cursor: 'pointer'}}  onClick = {flizPay} className = 'shop'  style = {{height:'60px',width:'190px',background:'transparent',border:'solid 2px gold',position:'absolute', top:'60vh',right:'37vw',zIndex:2, fontFamily:'Oswald',fontSize:'22px',color:'white'}}>Purchase</motion.button>
</div>
<motion.div whileHover = {{cursor:'pointer'}} onClick = {() => {SetTraps(!traps)}} style={{position:'absolute',top:'91vh', left:'2vw',height:'185px', width:'230px', zIndex:3, border:'double 3px black', borderRadius:'5px',backgroundColor:'rgb(26, 26, 25)'}}>
    <img style={{ top:traps? '-71vh' : '0vh', left:traps? '3vw' : '0vw',height:traps? '600px' : '180px', width:traps? '535px' : '160px', zIndex:3, backgroundColor:'transparent', position:'relative',}} src = {fronty}/>
  </motion.div>
  
</div>








<div className = 'dave'>
  <div className = 'kass'>
  <img className = 'blood' style={{display:traps? 'none':'block' }} src = {hood}/>
  </div>
  <div className = 'e'>
  <motion.i whileHover={{cursor:'pointer'}} onClick = {() => {setLink3(false)}} style={{zIndex:3, color:'gold', fontSize:'18px', position:'absolute',left:'48vw', top:'11vh', background:'rgb(46, 44, 44)', borderRadius:'100%',padding:'10px'}} class="fa-solid fa-arrow-left"/>
  </div>
  <div className = 'wu' >
  <motion.i whileHover={{cursor:'pointer'}} onClick = {() => {setLink3(false)}} style={{zIndex:3, color:'gold', fontSize:'18px',  background:'rgb(46, 44, 44)', borderRadius:'100%',padding:'10px'}} class="fa-solid fa-arrow-left"/>
  </div>
  <div className = 'wuz' >
  <motion.i whileHover={{cursor:'pointer'}} onClick = {() => {setLink3(false)}} style={{zIndex:3, color:'gold', fontSize:'28px',  background:'rgb(46, 44, 44)', borderRadius:'100%',padding:'20px'}} class="fa-solid fa-arrow-left"/>
  </div>
  
  <h5 className = 'fluz'  >Zentyr Fliz JT</h5>
  <div className = 'e' style = {{height:'1px', width:'240px', border:'gold dotted 1px ',right:'34vw', top:'30vh',position:'absolute' }}/>
  <p className = 'hodman'>It doesn't get more hoodman than this. A Blackout layout, comfy puffy jacket and a London style Hoodie, A Must.</p>
  <div className = 'e'style = {{ width:'240px', border:'gold solid 1px ',right:'34vw', top:'41vh',position:'absolute' }}/>
  <h5 className = 'e' style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'25px', fontWeight:'100', position:'absolute',right:'44vw', top:'38vh'}} >Color</h5>
  <motion.button whileHover = {{cursor:gooseg? 'default': 'pointer'}} className = 'e'  style  = {{background:'black', padding:'20px', borderRadius:'100%',position:'absolute',right:'44.5vw', top:'50vh',border:'solid goldenrod 1.5px' }}/>
  

<div className = 'e'>    <motion.button whileHover = {{cursor: 'pointer'}}  onClick = {flizPay} className = 'shop'  style = {{height:'60px',width:'190px',background:'transparent',border:'solid 2px gold',position:'absolute', top:'60vh',right:'37vw',zIndex:2, fontFamily:'Oswald',fontSize:'22px',color:'white'}}>Purchase</motion.button>
</div>
   
<div className = 'pai'>    
  <motion.button whileHover = {{cursor: 'pointer'}}  onClick = {flizPay} className = 'shop'  style = {{height:'60px',width:'190px',background:'transparent',border:'solid 2px gold',zIndex:2, fontFamily:'Oswald',fontSize:'22px',color:'white'}}>Purchase</motion.button>
</div>
<div className = 'pait'>    
  <motion.button whileHover = {{cursor: 'pointer'}}  onClick = {flizPay} className = 'shop'  style = {{height:'67px',width:'240px',background:'transparent',border:'solid 2px gold',zIndex:2, fontFamily:'Oswald',fontSize:'28px',color:'white'}}>Purchase</motion.button>
</div>
<div className = 'paitz'>    
  <motion.button whileHover = {{cursor: 'pointer'}}  onClick = {flizPay} className = 'shop'  style = {{height:'77px',width:'280px',background:'transparent',border:'solid 2px gold',zIndex:2, fontFamily:'Oswald',fontSize:'33px',color:'white'}}>Purchase</motion.button>
</div>

<motion.div className = 'apple'>
    <img style={{ top: '0vh', left: '0vw',height: '180px', width:'160px', zIndex:3, backgroundColor:'transparent', position:'relative',}} src = {fronty}/>
  </motion.div> 

   <div className = 'e'>
    <motion.div whileHover = {{cursor:'pointer'}} onClick = {() => {SetTraps(!traps)}} style={{position:'absolute',top:'80vh', left:'12vw',height:'185px', width:'230px', zIndex:3, border:'double 3px black', borderRadius:'5px',backgroundColor:'rgb(26, 26, 25)'}}>
    <img style={{ top:traps? '-66vh' : '0vh', left:traps? '3vw' : '0vw',height:traps? '550px' : '180px', width:traps? '535px' : '160px', zIndex:3, backgroundColor:'transparent', position:'relative',}} src = {fronty}/>
  </motion.div>
   
  <motion.div whileHover = {{cursor:'pointer'}} onClick = {() => {SetTraps(!traps)}} style={{position:'absolute',top:'80vh', left:'12vw',height:'185px', width:'230px', zIndex:3, border:'double 3px black', borderRadius:'5px',backgroundColor:'rgb(26, 26, 25)', display:traps? 'block' : 'none'}}>
    <img style={{ top: '0vh', left: '0vw',height: '180px', width: '160px', zIndex:3, backgroundColor:'transparent', position:'relative',}} src = {fronty}/>
  </motion.div>
  </div>
  </div>
</motion.div></div>) : (
    <div>

   {link2? (<div>
    <motion.div initial = {{display:'none'}} animate = {{display:link2? 'block' : 'none'}} transition = {{delay:2.4}}>

    <div className = 'money'>
    <div className = 'dadd'>
  <img className = 'momm' style={{display:traps? 'none':'block' }} src = {mc}/>
  </div>
  <div className = 'e'>
  <motion.i whileHover={{cursor:'pointer'}} onClick = {() => {setLink2(false)}} style={{zIndex:3, color:'gold', fontSize:'18px', position:'absolute',left:'48vw', top:'11vh', background:'rgb(46, 44, 44)', borderRadius:'100%',padding:'10px'}} class="fa-solid fa-arrow-left"/>
  </div>
  <h5  className = 'zann' >Zentyr JT</h5>
  <div className = 'e' style = {{height:'1px', width:'240px', border:'gold dotted 1px ',right:'34vw', top:'30vh',position:'absolute' }}/>
  <p className = 'sonn' >The Modern Blackout, Puffiest & Notorious Of All Zentyr JT Puff Drill Jacket For Sale!!</p>
  <div className = 'e' style = {{ width:'240px', border:'gold solid 1px ',right:'34vw', top:'41vh',position:'absolute' }}/>
  <h5 className = 'e' style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'25px', fontWeight:'100', position:'absolute',right:'47vw', top:'40vh'}} >Color</h5>
  <motion.button className = 'e'  whileHover = {{cursor:gooseg? 'default': 'pointer'}}  style  = {{background:'black', padding:'20px', borderRadius:'100%',position:'absolute',right:'47.5vw', top:'50vh',border:'solid goldenrod 1.5px' }}/>
  <motion.button className = 'e'  whileHover = {{cursor:gooseg? 'default': 'pointer'}}  style  = {{background:'darkred', padding:'20px', borderRadius:'100%',position:'absolute',right:'41.5vw', top:'50vh',border:'solid goldenrod 1.5px' }}/>
  <motion.button className = 'e'  whileHover = {{cursor:gooseg? 'default': 'pointer'}}  style  = {{background:'darkblue', padding:'20px', borderRadius:'100%',position:'absolute',right:'35.5vw', top:'50vh',border:'solid goldenrod 1.5px' }}/>

<div className = 'e' >
    <motion.button onClick = {jtPay} className = 'shop'  style = {{height:'60px',width:'190px',background:'transparent',border:'solid 2px gold',position:'absolute', top:'60vh',right:'37vw',zIndex:2, fontFamily:'Oswald',fontSize:'22px',color:'white'}}>Purchase</motion.button>
   </div>
   <motion.div whileHover = {{cursor:'pointer'}} onClick = {() => {SetTraps(!traps)}} style={{position:'absolute',top:'85vh', left:'2vw',height:'185px', width:'230px', zIndex:3, border:'double 3px black', borderRadius:'5px',backgroundColor:'rgb(26, 26, 25)'}}>
    <img style={{ top:traps? '-66vh' : '0vh', left:traps? '7vw' : '0vw',height:traps? '550px' : '180px', width:traps? '435px' : '160px', zIndex:3, backgroundColor:'transparent', position:'relative',}} src = {red1}/>
  </motion.div>
  </div>








  <div className = 'dave'>

  <div className = 'dad'>
  <img className = 'mom' style={{display:traps? 'none':'block' }} src = {mc}/>
  </div>
  <div className = 'e'>
  <motion.i whileHover={{cursor:'pointer'}} onClick = {() => {setLink2(false)}} style={{zIndex:3, color:'gold', fontSize:'18px', position:'absolute',left:'48vw', top:'11vh', background:'rgb(46, 44, 44)', borderRadius:'100%',padding:'10px'}} class="fa-solid fa-arrow-left"/>
  </div>

  <div className = 'dp'>  <motion.i whileHover={{cursor:'pointer'}} onClick = {() => {setLink2(false)}} style={{zIndex:3, color:'gold', fontSize:'18px', background:'rgb(46, 44, 44)', borderRadius:'100%',padding:'10px'}} class="fa-solid fa-arrow-left"/>
</div>

<div className = 'dpd'>  <motion.i whileHover={{cursor:'pointer'}} onClick = {() => {setLink2(false)}} style={{zIndex:3, color:'gold', fontSize:'25px', background:'rgb(46, 44, 44)', borderRadius:'100%',padding:'20px'}} class="fa-solid fa-arrow-left"/>
</div>

  <h5  className = 'zan' >Zentyr JT</h5>
  <div className = 'e' style = {{height:'1px', width:'240px', border:'gold dotted 1px ',right:'34vw', top:'30vh',position:'absolute' }}/>
  <p className = 'son' >The Modern Blackout, Puffiest & Notorious Of All Zentyr JT Puff Drill Jacket For Sale!!</p>
  <div className = 'e' style = {{ width:'240px', border:'gold solid 1px ',right:'34vw', top:'41vh',position:'absolute' }}/>
  <h5 className = 'e' style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'25px', fontWeight:'100', position:'absolute',right:'44vw', top:'38vh'}} >Color</h5>
  <motion.button className = 'e'  whileHover = {{cursor:gooseg? 'default': 'pointer'}}  style  = {{background:'black', padding:'20px', borderRadius:'100%',position:'absolute',right:'44.5vw', top:'50vh',border:'solid goldenrod 1.5px' }}/>
  <motion.button className = 'e'  whileHover = {{cursor:gooseg? 'default': 'pointer'}}  style  = {{background:'darkred', padding:'20px', borderRadius:'100%',position:'absolute',right:'40.5vw', top:'50vh',border:'solid goldenrod 1.5px' }}/>
  <motion.button className = 'e'  whileHover = {{cursor:gooseg? 'default': 'pointer'}}  style  = {{background:'darkblue', padding:'20px', borderRadius:'100%',position:'absolute',right:'36.5vw', top:'50vh',border:'solid goldenrod 1.5px' }}/>

<div className = 'e' >
    <motion.button onClick = {jtPay} className = 'shop'  style = {{height:'60px',width:'190px',background:'transparent',border:'solid 2px gold',position:'absolute', top:'60vh',right:'37vw',zIndex:2, fontFamily:'Oswald',fontSize:'22px',color:'white'}}>Purchase</motion.button>
   </div>
   <div className = 'etd' >
    <motion.button onClick = {jtPay} className = 'shop'  style = {{height:'60px',width:'190px',background:'transparent',border:'solid 2px gold',position:'absolute',  fontFamily:'Oswald',fontSize:'22px',color:'white'}}>Purchase</motion.button>
   </div>

   <div className = 'etdz' >
    <motion.button onClick = {jtPay} className = 'shop'  style = {{height:'70px',width:'250px',background:'transparent',border:'solid 2px gold',position:'absolute',  fontFamily:'Oswald',fontSize:'28px',color:'white'}}>Purchase</motion.button>
   </div>
   <div className = 'etdzf' >
    <motion.button onClick = {jtPay} className = 'shop'  style = {{height:'70px',width:'250px',background:'transparent',border:'solid 2px gold',position:'absolute',  fontFamily:'Oswald',fontSize:'28px',color:'white'}}>Purchase</motion.button>
   </div>
   
   <motion.div className = 'hunch'>
    <img style={{height: '180px', width: '160px', zIndex:3, backgroundColor:'transparent',}} src = {red1}/>
  </motion.div>




   <div className = 'e'>
    <motion.div whileHover = {{cursor:'pointer'}} onClick = {() => {SetTraps(!traps)}} style={{position:'absolute',top:'80vh', left:'12vw',height:'185px', width:'230px', zIndex:3, border:'double 3px black', borderRadius:'5px',backgroundColor:'rgb(26, 26, 25)'}}>
    <img style={{ top:traps? '-66vh' : '0vh', left:traps? '5vw' : '0vw',height:traps? '550px' : '180px', width:traps? '435px' : '160px', zIndex:3, backgroundColor:'transparent', position:'relative',}} src = {red1}/>
  </motion.div>
   
  <motion.div whileHover = {{cursor:'pointer'}} onClick = {() => {SetTraps(!traps)}} style={{position:'absolute',top:'80vh', left:'12vw',height:'185px', width:'230px', zIndex:3, border:'double 3px black', borderRadius:'5px',backgroundColor:'rgb(26, 26, 25)', display:traps? 'block' : 'none'}}>
    <img style={{ top: '0vh', left: '0vw',height: '180px', width: '160px', zIndex:3, backgroundColor:'transparent', position:'relative',}} src = {red1}/>
  </motion.div>
  </div>
  </div>
</motion.div></div>) : (
    <div>
{link1? (<div><motion.div initial = {{display:'none'}} animate = {{display:link1? 'block' : 'none'}} transition = {{delay:2.4}}>



<div className = 'money'>
<div className = 'backedaa'>
  <img className = 'bojtt' style={{display:traps? 'none':'block'}} src = {traped}/>
  </div>
  <div className = 'e'>
  <motion.i whileHover={{cursor:'pointer'}} onClick = {() => {setLink1(false)}} style={{zIndex:3, color:'gold', fontSize:'18px', position:'absolute',left:'48vw', top:'11vh', background:'rgb(46, 44, 44)', borderRadius:'100%',padding:'10px'}} class="fa-solid fa-arrow-left"/>
</div>
  <h5  className ='zbb' >Zentyr Blackout JT</h5>
  <div className = 'g' />
  <p className = 'paa' >Elevate Your Clothings With A Pure Blackout, Puffy & Rich In Coziness London Drill Style Jacket.</p>
  <div  style = {{ width:'240px', border:'gold solid 1px ',right:'35vw', top:'38vh',position:'absolute' }}/>
  <h5  style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'25px', fontWeight:'100', position:'absolute',right:'48vw', top:'35vh'}} >Color</h5>
  <motion.button whileHover = {{cursor:gooseg? 'default': 'pointer'}} onClick = {() => {SetGooseB(false);SetGooseR(false);SetGooseG(true); SetGooseW(false); SetGooseBL(false)}} style  = {{background:'black', padding:'20px', borderRadius:'100%',position:'absolute',right:'48.5vw', top:'45vh',border:'solid goldenrod 1.5px' }}/>
    <div className = 'e'>
    <motion.button onClick = {trapPay} className = 'shop'  style = {{height:'60px',width:'190px',background:'transparent',border:'solid 2px gold',position:'absolute', top:'55vh',right:'39vw',zIndex:2, fontFamily:'Oswald',fontSize:'22px',color:'white'}}>Purchase</motion.button>
    </div>
    <motion.div whileHover = {{cursor:'pointer'}} onClick = {() => {SetTraps(!traps)}} style={{position:'absolute',top:'90vh', left:'2vw',height:'185px', width:'230px', zIndex:3, border:'double 3px black', borderRadius:'5px',backgroundColor:'rgb(26, 26, 25)'}}>
    <img style={{ top:traps? '-70vh' : '0vh', left:traps? '3vw' : '0vw',height:traps? '550px' : '180px', width:traps? '515px' : '160px', zIndex:3, backgroundColor:'transparent', position:'relative',}} src = {trapedb}/>
  </motion.div>

  </div>






  <div className = 'dave'>
  <div className = 'backeda'>
  <img className = 'bojt' style={{display:traps? 'none':'block'}} src = {traped}/>
  </div>
  <div className = 'e'>
  <motion.i whileHover={{cursor:'pointer'}} onClick = {() => {setLink1(false)}} style={{zIndex:3, color:'gold', fontSize:'18px', position:'absolute',left:'48vw', top:'11vh', background:'rgb(46, 44, 44)', borderRadius:'100%',padding:'10px'}} class="fa-solid fa-arrow-left"/>
</div>
<div className = 'ff'>
<motion.i  whileHover={{cursor:'pointer'}} onClick = {() => {setLink1(false)}} style={{zIndex:3, color:'gold', fontSize:'18px', position:'absolute',left:'78vw', top:'9vh', background:'rgb(46, 44, 44)', borderRadius:'100%',padding:'10px'}} class="fa-solid fa-arrow-left"/>
</div>
<div className = 'ffz'>
<motion.i  whileHover={{cursor:'pointer'}} onClick = {() => {setLink1(false)}} style={{zIndex:3, color:'gold', fontSize:'24px', position:'absolute',left:'78vw', top:'9vh', background:'rgb(46, 44, 44)', borderRadius:'100%',padding:'20px'}} class="fa-solid fa-arrow-left"/>
</div>

  <h5  className ='zb' >Zentyr Blackout JT</h5>
  <div className = 'g' />
  <p className = 'pa' >Elevate Your Clothings With A Pure Blackout, Puffy & Rich In Coziness London Drill Style Jacket.</p>
  <div className = 'e' style = {{ width:'240px', border:'gold solid 1px ',right:'34vw', top:'41vh',position:'absolute' }}/>
  <h5  className = 'e' style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'25px', fontWeight:'100', position:'absolute',right:'44vw', top:'38vh'}} >Color</h5>
  <motion.button className = 'e' whileHover = {{cursor:gooseg? 'default': 'pointer'}} onClick = {() => {SetGooseB(false);SetGooseR(false);SetGooseG(true); SetGooseW(false); SetGooseBL(false)}} style  = {{background:'black', padding:'20px', borderRadius:'100%',position:'absolute',right:'44.5vw', top:'50vh',border:'solid goldenrod 1.5px' }}/>
    <div className = 'e'>
    <motion.button onClick = {trapPay} className = 'shop'  style = {{height:'60px',width:'190px',background:'transparent',border:'solid 2px gold',position:'absolute', top:'60vh',right:'37vw',zIndex:2, fontFamily:'Oswald',fontSize:'22px',color:'white'}}>Purchase</motion.button>
    </div>
    <div className = 'py'>
    <motion.button onClick = {trapPay} className = 'shop' style = {{height:'60px',width:'190px',background:'transparent',border:'solid 2px gold' ,zIndex:2, fontFamily:'Oswald',fontSize:'22px',color:'white'}}>Purchase</motion.button>
    </div>

    <div className = 'pyz'>
    <motion.button onClick = {trapPay} className = 'shop' style = {{height:'66px',width:'260px',background:'transparent',border:'solid 2px gold' ,zIndex:2, fontFamily:'Oswald',fontSize:'30px',color:'white'}}>Purchase</motion.button>
    </div>
    <div className = 'pust'>
    <motion.button onClick = {trapPay} className = 'shop' style = {{height:'66px',width:'260px',background:'transparent',border:'solid 2px gold' ,zIndex:2, fontFamily:'Oswald',fontSize:'30px',color:'white'}}>Purchase</motion.button>
    </div>
    <div className = 'pustt'>
    <motion.button onClick = {trapPay} className = 'shop' style = {{height:'66px',width:'260px',background:'transparent',border:'solid 2px gold' ,zIndex:2, fontFamily:'Oswald',fontSize:'30px',color:'white'}}>Purchase</motion.button>
    </div>
    
    
    <motion.div  className = 'man' >
    <img style={{ top:traps? '-66vh' : '0vh', left: '0vw',height: '180px', width: '160px', zIndex:3, backgroundColor:'transparent', position:'relative',}} src = {trapedb}/>
  </motion.div>
  
   <div className = 'e'>
    <motion.div whileHover = {{cursor:'pointer'}} onClick = {() => {SetTraps(!traps)}} style={{position:'absolute',top:'80vh', left:'12vw',height:'185px', width:'230px', zIndex:3, border:'double 3px black', borderRadius:'5px',backgroundColor:'rgb(26, 26, 25)'}}>
    <img style={{ top:traps? '-66vh' : '0vh', left:traps? '3vw' : '0vw',height:traps? '550px' : '180px', width:traps? '515px' : '160px', zIndex:3, backgroundColor:'transparent', position:'relative',}} src = {trapedb}/>
  </motion.div>
   
  <motion.div whileHover = {{cursor:'pointer'}} onClick = {() => {SetTraps(!traps)}} style={{position:'absolute',top:'80vh', left:'12vw',height:'185px', width:'230px', zIndex:3, border:'double 3px black', borderRadius:'5px',backgroundColor:'rgb(26, 26, 25)', display:traps? 'block' : 'none'}}>
    <img style={{ top: '0vh', left: '0vw',height: '180px', width: '180px', zIndex:3, backgroundColor:'transparent', position:'relative',}} src = {traped}/>
  </motion.div>
</div>
</div>
</motion.div></div>) : (<div>{link? 
(
<motion.div initial = {{display:'none'}} animate = {{display:link? 'block' : 'none'}} transition = {{delay:2.4}}>



<div className = 'money'>

  
<div className = 'backedd'>
  <img className = 'goostt' src = {goosy}/>
  </div>
  <div >
  <img style={{position:'absolute', top:!gooseb? '83vh' : '12.5vh', left:!gooseb? '13vw' : '11vw',height:!gooseb? '150px' : '600px', width:!gooseb? '150px' : '635px', zIndex:3, backgroundColor:'transparent', }} src = {black}/>
  <img style={{position:'absolute', top:!gooser? '83vh' : '12.5vh', left:!gooser? '25vw' : '11vw',height:!gooser? '150px' : '600px', width:!gooser? '150px' : '635px',zIndex:3, backgroundColor:'transparent', }} src = {red}/>
  <img style={{position:'absolute',  top:!goosew? '83vh' : '12.5vh', left:!goosew? '37vw' : '11vw',height:!goosew? '150px' : '600px', width:!goosew? '150px' : '635px', zIndex:3, backgroundColor:'transparent', }} src = {white}/>
  <img style={{position:'absolute',  top:!goosebl? '83vh' : '12.5vh', left:!goosebl? '50vw' : '11vw',height:!goosebl? '150px' : '600px', width:!goosebl? '150px' : '635px', zIndex:3, backgroundColor:'transparent', }} src = {blue}/>
  </div>
  <motion.i whileHover={{cursor:'pointer'}} onClick = {() => {setLink(false)}} style={{zIndex:3, color:'gold', fontSize:'18px', position:'absolute',right:'12vw', top:'9vh', background:'rgb(46, 44, 44)', borderRadius:'100%',padding:'10px'}} class="fa-solid fa-arrow-left"/>


<h5 className = 'zackk' >Zentyr Glace</h5>
  <p className = 'zeckk' >The Top Tier Goose Jacket, Perfect For The Winter, Perfect For The Design, And Exclusive For Comfort & Warmth.</p>
  <h5 style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'25px', fontWeight:'100', position:'absolute',right:'36vw', top:'32.5vh'}} >Color</h5>
<div>
  <motion.button whileHover = {{cursor:gooseg? 'default': 'pointer'}} onClick = {() => {SetGooseB(false);SetGooseR(false);SetGooseG(true); SetGooseW(false); SetGooseBL(false)}} style  = {{background:'linear-gradient(to right, darkgrey,grey)', padding:'20px', borderRadius:'100%',position:'absolute',right:'36.5vw', top:'43vh',border:gooseg && !gooser && !goosew && !goosebl && !gooseb ? 'solid goldenrod 1.5px' : 'none'}}/>
<motion.button whileHover = {{cursor:gooseb? 'default': 'pointer'}} onClick = {() => {SetGooseB(true);SetGooseR(false);SetGooseG(false); SetGooseW(false);SetGooseBL(false)}} style  = {{background:'black', padding:'20px', borderRadius:'100%',position:'absolute',right:'31.5vw', top:'43vh',border:!gooseg && !gooser &&!goosebl && !goosew && gooseb ? 'solid goldenrod 1.5px' : 'none'}}/>
<motion.button whileHover = {{cursor:gooser? 'default': 'pointer'}} onClick = {() => {SetGooseB(false);SetGooseR(true);SetGooseG(false); SetGooseW(false);SetGooseBL(false)}} style  = {{background:'red', padding:'20px', borderRadius:'100%',position:'absolute',right:'26.5vw', top:'43vh',border:!gooseg && gooser && !goosebl && !goosew && !gooseb ? 'solid goldenrod 1.5px' : 'none'}}/>
<motion.button whileHover = {{cursor:goosew? 'default': 'pointer'}}onClick = {() => {SetGooseB(false);SetGooseR(false);SetGooseG(false); SetGooseW(true);SetGooseBL(false)}} style  = {{background:'white', padding:'20px', borderRadius:'100%',position:'absolute',right:'21.5vw', top:'43vh',border:!gooseg && !gooser && !goosebl && goosew && !gooseb ? 'solid goldenrod 1.5px' : 'none'}}/>
<motion.button whileHover = {{cursor:goosebl? 'default': 'pointer'}}onClick = {() => {SetGooseB(false);SetGooseR(false);SetGooseG(false); SetGooseW(false);SetGooseBL(true)}} style  = {{background:'blue', padding:'20px', borderRadius:'100%',position:'absolute',right:'16.5vw', top:'43vh',border:!gooseg && !gooser && !goosew && !gooseb && goosebl? 'solid goldenrod 1.5px' : 'none'}}/>
</div>

<motion.button onClick = {goosePay} className = 'shop'  style = {{height:'65px',width:'230px',background:'transparent',border:'solid 2px gold',position:'absolute', top:'55vh',right:'23.6vw',zIndex:2, fontFamily:'Oswald',fontSize:'24px',color:'white'}}>Purchase</motion.button>


</div>

<div className = 'dave'>
<div className='bam'>
  <img style={{position:'absolute',  top: '30vh', right: '9vw',height:'200px', width: '215px', zIndex:3, backgroundColor:'transparent', }} src = {black}/>
  <img style={{position:'absolute',  top: '50vh', right: '9vw',height:'200px', width: '215px', zIndex:3, backgroundColor:'transparent', }} src = {red}/>
  <img style={{position:'absolute',  top: '70vh', right: '9vw',height:'200px', width: '215px', zIndex:3, backgroundColor:'transparent', }} src = {white}/>
  <img style={{position:'absolute',  top: '90vh', right: '9vw',height:'200px', width: '215px', zIndex:3, backgroundColor:'transparent', }} src = {blue}/>

  </div>
  <div  className = 's' style = {{height:'1px', width:'240px', border:'gold dotted 1px ',right:'34vw', top:'30vh',position:'absolute' }}/>
  <div className = 'ss' style = {{ width:'240px', border:'gold solid 1px ',right:'34vw', top:'43vh',position:'absolute' }}/>
  <h5 className = 'cold' style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'25px', fontWeight:'100', position:'absolute',right:'44vw', top:'40.5vh'}} >Color</h5>
 

<div className = 'jam'>
<motion.button onClick = {goosePay} className = 'shop'  style = {{height:'80px',width:'290px',background:'transparent',border:'solid 2px gold',position:'absolute', top:'82vh',right:'60.6vw',zIndex:2, fontFamily:'Oswald',fontSize:'31px',color:'white'}}>Purchase</motion.button>
</div>



















  <div className = 'backed'>
  <img className = 'goost' src = {goosy}/>
  </div>
  
  <div className='bruhv'>
  <img style={{position:'absolute', top:!gooseb? '80vh' : '12.5vh', left:!gooseb? '13vw' : '11vw',height:!gooseb? '150px' : '600px', width:!gooseb? '150px' : '635px', zIndex:3, backgroundColor:'transparent', }} src = {black}/>
  <img style={{position:'absolute', top:!gooser? '80vh' : '12.5vh', left:!gooser? '23vw' : '11vw',height:!gooser? '150px' : '600px', width:!gooser? '150px' : '635px',zIndex:3, backgroundColor:'transparent', }} src = {red}/>
  <img style={{position:'absolute',  top:!goosew? '80vh' : '12.5vh', left:!goosew? '33vw' : '11vw',height:!goosew? '150px' : '600px', width:!goosew? '150px' : '635px', zIndex:3, backgroundColor:'transparent', }} src = {white}/>
  <img style={{position:'absolute',  top:!goosebl? '80vh' : '12.5vh', left:!goosebl? '43vw' : '11vw',height:!goosebl? '150px' : '600px', width:!goosebl? '150px' : '635px', zIndex:3, backgroundColor:'transparent', }} src = {blue}/>
  </div>

  <div className='bruhvz'>
  <img style={{position:'absolute', top: '80vh' , left: '13vw' ,height: '150px' , width:'150px' , zIndex:3, backgroundColor:'transparent', }} src = {black}/>
  <img style={{position:'absolute', top: '80vh' , left: '43vw' ,height: '150px' , width:'150px' ,zIndex:3, backgroundColor:'transparent', }} src = {red}/>
  <img style={{position:'absolute', top: '80vh' , left: '73vw' ,height: '150px' , width:'150px' , zIndex:3, backgroundColor:'transparent', }} src = {white}/>
  <img style={{position:'absolute', top: '105vh' , left: '13vw' ,height: '150px' , width:'150px' , zIndex:3, backgroundColor:'transparent', }} src = {blue}/>
  </div>

  <div className='bruhvzf'>
  <img style={{position:'absolute', top: '35vh' , right: '4vw' ,height: '140px' , width:'140px' , zIndex:3, backgroundColor:'transparent', }} src = {black}/>
  <img style={{position:'absolute', top: '50vh' , right: '4vw' ,height: '140px' , width:'140px' ,zIndex:3, backgroundColor:'transparent', }} src = {red}/>
  <img style={{position:'absolute', top: '65vh' , right: '4vw' ,height: '140px' , width:'140px' , zIndex:3, backgroundColor:'transparent', }} src = {white}/>
  <img style={{position:'absolute', top: '80vh' , right: '4vw' ,height: '140px' , width:'140px' , zIndex:3, backgroundColor:'transparent', }} src = {blue}/>
  </div>
  <div className='fresh'>
  <img style={{position:'absolute', top: '50vh' , right: '18vw' ,height: '170px' , width:'170px' , zIndex:3, backgroundColor:'transparent', }} src = {black}/>
  <img style={{position:'absolute', top: '73vh' , right: '18vw' ,height: '170px' , width:'170px' ,zIndex:3, backgroundColor:'transparent', }} src = {red}/>
  <img style={{position:'absolute', top: '96vh' , right: '18vw' ,height: '170px' , width:'170px' , zIndex:3, backgroundColor:'transparent', }} src = {white}/>
  <img style={{position:'absolute', top: '119vh' , right: '18vw' ,height: '170px' , width:'170px' , zIndex:3, backgroundColor:'transparent', }} src = {blue}/>
  </div>
  
  <div className='bruhvd'>
  <img style={{position:'absolute',  top: '110vh', right: '59vw',height:'120px', width: '135px', zIndex:3, backgroundColor:'transparent', }} src = {black}/>
  <img style={{position:'absolute',  top: '110vh', right: '19vw',height:'120px', width: '135px', zIndex:3, backgroundColor:'transparent', }} src = {red}/>
  <img style={{position:'absolute',  top: '130vh', right: '59vw',height:'120px', width: '135px', zIndex:3, backgroundColor:'transparent', }} src = {white}/>
  <img style={{position:'absolute',  top: '130vh', right: '19vw',height:'120px', width: '135px', zIndex:3, backgroundColor:'transparent', }} src = {blue}/>
  <motion.button onClick = {goosePay} className = 'shop'  style = {{height:'60px',width:'190px',background:'transparent',border:'solid 2px gold',position:'absolute', top:'152vh',right:'44.6vw',zIndex:2, fontFamily:'Oswald',fontSize:'22px',color:'white'}}>Purchase</motion.button>
  <motion.i whileHover={{cursor:'pointer'}} onClick = {() => {setLink(false)}} style={{zIndex:3, color:'gold', fontSize:'18px', position:'absolute',right:'12vw', top:'9vh', background:'rgb(46, 44, 44)', borderRadius:'100%',padding:'10px'}} class="fa-solid fa-arrow-left"/>

  </div>




<div className = 'le'>
  <motion.i  whileHover={{cursor:'pointer'}} onClick = {() => {setLink(false)}} style={{zIndex:3, color:'gold', fontSize:'18px', position:'absolute',left:'48vw', top:'11vh', background:'rgb(46, 44, 44)', borderRadius:'100%',padding:'10px'}} class="fa-solid fa-arrow-left"/>
  </div>

  <div className = 'lfe'>
  <motion.i  whileHover={{cursor:'pointer'}} onClick = {() => {setLink(false)}} style={{zIndex:3, color:'gold', fontSize:'25px', background:'rgb(46, 44, 44)', borderRadius:'100%',padding:'10px'}} class="fa-solid fa-arrow-left"/>
  </div>
  
  
  <div className = 'lfez'>
  <motion.i  whileHover={{cursor:'pointer'}} onClick = {() => {setLink(false)}} style={{zIndex:3, color:'gold', fontSize:'30px', background:'rgb(46, 44, 44)', borderRadius:'100%',padding:'25px'}} class="fa-solid fa-arrow-left"/>
  </div>
  <div className = 'lfezt'>
  <motion.i  whileHover={{cursor:'pointer'}} onClick = {() => {setLink(false)}} style={{zIndex:3, color:'gold', fontSize:'30px', background:'rgb(46, 44, 44)', borderRadius:'100%',padding:'25px'}} class="fa-solid fa-arrow-left"/>
  </div>







  <h5 className = 'zack' >Zentyr Glace</h5>
  <p className = 'zeck' >The Top Tier Goose Jacket, Perfect For The Winter, Perfect For The Design, And Exclusive For Comfort & Warmth.</p>
  <h5 className = 'cold' style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'25px', fontWeight:'100', position:'absolute',right:'44vw', top:'40.5vh'}} >Color</h5>
<div className = 'bzoz'>
  <motion.button whileHover = {{cursor:gooseg? 'default': 'pointer'}} onClick = {() => {SetGooseB(false);SetGooseR(false);SetGooseG(true); SetGooseW(false); SetGooseBL(false)}} style  = {{background:'linear-gradient(to right, darkgrey,grey)', padding:'20px', borderRadius:'100%',position:'absolute',right:'44.5vw', top:'53vh',border:gooseg && !gooser && !goosew && !goosebl && !gooseb ? 'solid goldenrod 1.5px' : 'none'}}/>
<motion.button whileHover = {{cursor:gooseb? 'default': 'pointer'}} onClick = {() => {SetGooseB(true);SetGooseR(false);SetGooseG(false); SetGooseW(false);SetGooseBL(false)}} style  = {{background:'black', padding:'20px', borderRadius:'100%',position:'absolute',right:'40.5vw', top:'53vh',border:!gooseg && !gooser &&!goosebl && !goosew && gooseb ? 'solid goldenrod 1.5px' : 'none'}}/>
<motion.button whileHover = {{cursor:gooser? 'default': 'pointer'}} onClick = {() => {SetGooseB(false);SetGooseR(true);SetGooseG(false); SetGooseW(false);SetGooseBL(false)}} style  = {{background:'red', padding:'20px', borderRadius:'100%',position:'absolute',right:'36.5vw', top:'53vh',border:!gooseg && gooser && !goosebl && !goosew && !gooseb ? 'solid goldenrod 1.5px' : 'none'}}/>
<motion.button whileHover = {{cursor:goosew? 'default': 'pointer'}}onClick = {() => {SetGooseB(false);SetGooseR(false);SetGooseG(false); SetGooseW(true);SetGooseBL(false)}} style  = {{background:'white', padding:'20px', borderRadius:'100%',position:'absolute',right:'32.5vw', top:'53vh',border:!gooseg && !gooser && !goosebl && goosew && !gooseb ? 'solid goldenrod 1.5px' : 'none'}}/>
<motion.button whileHover = {{cursor:goosebl? 'default': 'pointer'}}onClick = {() => {SetGooseB(false);SetGooseR(false);SetGooseG(false); SetGooseW(false);SetGooseBL(true)}} style  = {{background:'blue', padding:'20px', borderRadius:'100%',position:'absolute',right:'32.5vw', top:'53vh',border:!gooseg && !gooser && !goosew && !gooseb && goosebl? 'solid goldenrod 1.5px' : 'none'}}/>
</div>

<div className = 'ejva'>
<motion.button onClick = {goosePay} className = 'shop'  style = {{height:'60px',width:'190px',background:'transparent',border:'solid 2px gold',position:'absolute', top:'162vh',right:'56.6vw',zIndex:2, fontFamily:'Oswald',fontSize:'22px',color:'white'}}>Purchase</motion.button>
</div>

<div className = 'ejvaz'>
<motion.button onClick = {goosePay} className = 'shop'  style = {{height:'65px',width:'220px',background:'transparent',border:'solid 2px gold',position:'absolute', top:'97vh',right:'65.6vw',zIndex:2, fontFamily:'Oswald',fontSize:'29px',color:'white'}}>Purchase</motion.button>
</div>

<div className = 'eat'>
    <motion.button onClick = {goosePay} className = 'shop'  style = {{height:'60px',width:'190px',background:'transparent',border:'solid 2px gold',position:'absolute', top:'126vh',left:'9.6vw',zIndex:2, fontFamily:'Oswald',fontSize:'22px',color:'white'}}>Purchase</motion.button>
    </div>

<div className = 'e'>
    <motion.button onClick = {goosePay} className = 'shop'  style = {{height:'60px',width:'190px',background:'transparent',border:'solid 2px gold',position:'absolute', top:'66vh',right:'36.6vw',zIndex:2, fontFamily:'Oswald',fontSize:'22px',color:'white'}}>Purchase</motion.button>
    </div>
    </div>




</motion.div>
) : (<div>


  <motion.div initial = {{y:0}} animate = {{y:-1450}} transition = {{type:'tween', duration:1, delay:4}} style = {{backgroundColor:'black', height:'100vh', width:'100vw', zIndex:3,position:'absolute',top:'0vh',left:'0vw'}}>
  <div style = {{backgroundColor:'rgb(6, 6, 35)', height:'100vh', width:'100vw', zIndex:3,position:'absolute',top:'0vh',left:'0vw'}} />
  <motion.i initial = {{opacity:1}} animate = {{opacity:0, }} transition = {{duration:1.2,delay:3}}   className="king1 fa-solid fa-crown"/>
<motion.img src= {jan} initial = {{opacity:1}} animate = {{opacity:0, }} transition = {{duration:1.2,delay:3}} className = 'zents1' />
  </motion.div>


 
<div className = 'realone' style = {{backgroundColor:'rgb(26, 26, 35)', height:'100vh', width:'100vw', zIndex:1,position:'absolute',top:'0vh',left:'0vw',}} />



 
 
<div style = {{display:secound? 'block' : 'none'}}>
<div  data-aos = 'fade-up' className = 'origin-s'>
<img style={{height:'340px', width:'360px', zIndex:3, backgroundColor:'transparent' }} src = {goosy}/>
<Rating style = {{color:'white', position:'relative',right:'50px',top:'70px'}} name="half-rating-read" defaultValue={4.5} precision={0.5} readOnly/>
<h5  style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'38px', fontWeight:'100', position:'relative',right:'25px', bottom:'75px'}} >Zentyr Glace</h5>
<h3 style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'35px', fontWeight:'100', position:'relative',right:'75px', bottom:'90px'}}>425$</h3>
<button className = 'shop' onClick = {() => {setBuy(true);setLink(true)}}  style = {{height:'54px',width:'110px',border:'solid 2px black',left:'75px', bottom:'176px',position:'relative',zIndex:3, fontFamily:'Oswald',fontSize:'20px',backgroundColor:'white'}}>Buy</button>
<h3 style={{zIndex:3, color:'red', fontFamily:'Oswald', fontSize:'18px', fontWeight:'100', position:'relative',right:'10px', bottom:'245px', textDecoration:'line-through'}}>650$</h3>
<h6 style={{zIndex:3, fontFamily:'Oswald', fontSize:'15px', fontWeight:'100', position:'relative',right:'125px', bottom:'755px', color:'gold'}}>Customizeable </h6>
    </div>

    <div id = 'ballzt' className = 'origins-a' data-aos = 'fade-up' >
<img style={{height:'270px', width:'270px', zIndex:3, backgroundColor:'transparent', position:'relative',top:'30px' ,left:'60px'}} src = {traped}/>
<Rating style = {{color:'white', position:'relative',right:'189px',top:'175px'}} name="half-rating-read" defaultValue={4}  readOnly/>
<h5  style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'41px', fontWeight:'100', position:'relative',left:'15px', bottom:'5px', whiteSpace:"nowrap"}} >Zentyr Blackout JT</h5>
<h3 style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'35px', fontWeight:'100', position:'relative',right:'82px', bottom:'5px'}}>350$</h3>
<button className = 'shop' onClick = {() => {setBuy(true);setLink1(true)}} style = {{height:'50px',width:'110px',border:'solid 2px black',left:'75px', bottom:'90px',position:'relative',zIndex:3, fontFamily:'Oswald',fontSize:'19px',backgroundColor:'white'}}>Buy</button>
    </div>




    <div data-aos = 'fade-up' className = 'origins-b'>
<img style={{height:'350px', width:'350px', zIndex:3, backgroundColor:'transparent' }} src = {cler}/>
<Rating style = {{color:'white', position:'relative',right:'50px',top:'60px'}} name="half-rating-read" defaultValue={3.5} precision={0.5} readOnly/>
<h5  style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'35px', fontWeight:'100', position:'relative',right:'52px', bottom:'90px'}} >Zentyr JT</h5>
<h3 style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'35px', fontWeight:'100', position:'relative',right:'72px', bottom:'100px'}}>300$</h3>
<button className = 'shop'  onClick = {() => {setBuy(true);setLink2(true)}} style = {{height:'50px',width:'110px',border:'solid 2px black',left:'75px', bottom:'190px',position:'relative',zIndex:3, fontFamily:'Oswald',fontSize:'19px',backgroundColor:'white'}}>Buy</button>
<h6 style={{zIndex:3, fontFamily:'Oswald', fontSize:'15px', fontWeight:'100', position:'relative',right:'105px', bottom:'665px', color:'gold'}}>Customizeable </h6>

    </div>


    <div data-aos = 'fade-up' className = 'origins-c'>
<img style={{height:'350px', width:'350px', zIndex:3, backgroundColor:'transparent' }} src = {hood}/>
<Rating style = {{color:'white', position:'relative',right:'50px',top:'50px'}} name="half-rating-read" defaultValue={3.5} precision={0.5} readOnly/>
<h5  style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'35px', fontWeight:'100', position:'relative',right:'29px', bottom:'100px'}} >Zentyr Fliz JT</h5>
<h3 style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'35px', fontWeight:'100', position:'relative',right:'72px', bottom:'110px'}}>325$</h3>
<button className = 'shop' onClick = {() => {setBuy(true);setLink3(true)}}   style = {{height:'50px',width:'110px',border:'solid 2px black',left:'75px', bottom:'196px',position:'relative',zIndex:3, fontFamily:'Oswald',fontSize:'19px',backgroundColor:'white'}}>Buy</button>

    </div>

    <div data-aos = 'fade-up' className = 'origins-d'>
<img style={{height:'320px', width:'320px', zIndex:3, backgroundColor:'transparent', position:'relative',top:'30px' }} src = {vont}/>
<Rating style = {{color:'white', position:'relative',right:'55px',top:'90px'}} name="half-rating-read" defaultValue={4.5} precision={0.5}  readOnly/>
<h5  style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'35px', fontWeight:'100', position:'relative',right:'29px', bottom:'60px'}} >Zentyr Vont JT</h5>
<h3 style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'35px', fontWeight:'100', position:'relative',right:'80px', bottom:'60px'}}>375$</h3>
<button className = 'shop'  onClick = {() => {setBuy(true);setLink4(true)}}  style = {{height:'50px',width:'110px',border:'solid 2px black',left:'75px', bottom:'146px',position:'relative',zIndex:3, fontFamily:'Oswald',fontSize:'19px',backgroundColor:'white'}}>Buy</button>

    </div>

    <div data-aos = 'fade-up' className = 'origins-e'>
<img style={{height:'350px', width:'350px', zIndex:3, backgroundColor:'transparent' }} src = {x}/>
<Rating style = {{color:'white', position:'relative',right:'80px',top:'70px'}} name="half-rating-read" defaultValue={3.5} precision={0.5} readOnly/>
<h5  style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'40px', fontWeight:'100', position:'relative',right:'39px', bottom:'100px'}} >Zentyr Banz JT</h5>
<h3 style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'40px', fontWeight:'100', position:'relative',right:'102px', bottom:'110px'}}>275$</h3>
<button className = 'shop' onClick = {() => {setBuy(true);setLink5(true)}}   style = {{height:'50px',width:'110px',border:'solid 2px black',left:'65px', bottom:'206px',position:'relative',zIndex:3, fontFamily:'Oswald',fontSize:'19px',backgroundColor:'white'}}>Buy</button>
<h6 style={{zIndex:3, fontFamily:'Oswald', fontSize:'15px', fontWeight:'100', position:'relative',right:'125px', bottom:'725px', color:'gold'}}>Customizeable </h6>

    </div>
  
  </div>





  
   





















   <motion.div style = {{display:secound? 'block' : 'none'}} initial = {{opacity:0}} animate = {{ opacity: secound? 1:0}} transition = {{delay:0.8,}}>
   <video autoPlay loop muted src = {yat1} className = 'nigga'>

   </video>
   <div className = 'blan'>
   <div id = 'ballz' data-aos = 'fade-up' className = 'origins'>
<img style={{height:'290px', width:'290px', zIndex:3, backgroundColor:'transparent' }} src = {goosy}/>
<Rating style = {{color:'white', position:'relative',right:'50px',top:'70px'}} name="half-rating-read" defaultValue={4.5} precision={0.5} readOnly/>
<h5  style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'35px', fontWeight:'100', position:'relative',right:'25px', bottom:'75px'}} >Zentyr Goose</h5>
<h3 style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'35px', fontWeight:'100', position:'relative',right:'75px', bottom:'90px'}}>425$</h3>
<button className = 'shop' onClick = {() => {setBuy(true);setLink(true)}}  style = {{height:'50px',width:'110px',border:'solid 2px black',left:'75px', bottom:'176px',position:'relative',zIndex:3, fontFamily:'Oswald',fontSize:'19px',backgroundColor:'white'}}>Buy</button>
<h3 style={{zIndex:3, color:'red', fontFamily:'Oswald', fontSize:'18px', fontWeight:'100', position:'relative',right:'10px', bottom:'245px', textDecoration:'line-through'}}>650$</h3>
<h6 style={{zIndex:3, fontFamily:'Oswald', fontSize:'15px', fontWeight:'100', position:'relative',right:'105px', bottom:'685px', color:'gold'}}>Customizeable </h6>
    </div>

    <div className = 'originsa' data-aos = 'fade-up' >
<img style={{height:'260px', width:'260px', zIndex:3, backgroundColor:'transparent', position:'relative',top:'20px' }} src = {traped}/>
<Rating style = {{color:'white', position:'relative',right:'59px',top:'95px'}} name="half-rating-read" defaultValue={4}  readOnly/>
<h5  style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'35px', fontWeight:'100', position:'relative',right:'5px', bottom:'55px', whiteSpace:"nowrap"}} >Zentyr Blackout JT</h5>
<h3 style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'35px', fontWeight:'100', position:'relative',right:'82px', bottom:'65px'}}>350$</h3>
<button className = 'shop' onClick = {() => {setBuy(true);setLink1(true)}} style = {{height:'50px',width:'110px',border:'solid 2px black',left:'75px', bottom:'150px',position:'relative',zIndex:3, fontFamily:'Oswald',fontSize:'19px',backgroundColor:'white'}}>Buy</button>
    </div>




    <div data-aos = 'fade-up' className = 'originsb'>
<img style={{height:'310px', width:'310px', zIndex:3, backgroundColor:'transparent' }} src = {cler}/>
<Rating style = {{color:'white', position:'relative',right:'50px',top:'60px'}} name="half-rating-read" defaultValue={3.5} precision={0.5} readOnly/>
<h5  style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'35px', fontWeight:'100', position:'relative',right:'52px', bottom:'90px'}} >Zentyr JT</h5>
<h3 style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'35px', fontWeight:'100', position:'relative',right:'72px', bottom:'100px'}}>300$</h3>
<button className = 'shop'  onClick = {() => {setBuy(true);setLink2(true)}} style = {{height:'50px',width:'110px',border:'solid 2px black',left:'75px', bottom:'190px',position:'relative',zIndex:3, fontFamily:'Oswald',fontSize:'19px',backgroundColor:'white'}}>Buy</button>
<h6 style={{zIndex:3, fontFamily:'Oswald', fontSize:'15px', fontWeight:'100', position:'relative',right:'105px', bottom:'665px', color:'gold'}}>Customizeable </h6>

    </div>


    <div data-aos = 'fade-up' className = 'originsc'>
<img style={{height:'320px', width:'320px', zIndex:3, backgroundColor:'transparent' }} src = {hood}/>
<Rating style = {{color:'white', position:'relative',right:'50px',top:'50px'}} name="half-rating-read" defaultValue={3.5} precision={0.5} readOnly/>
<h5  style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'35px', fontWeight:'100', position:'relative',right:'29px', bottom:'100px'}} >Zentyr Fliz JT</h5>
<h3 style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'35px', fontWeight:'100', position:'relative',right:'72px', bottom:'110px'}}>325$</h3>
<button className = 'shop' onClick = {() => {setBuy(true);setLink3(true)}}   style = {{height:'50px',width:'110px',border:'solid 2px black',left:'75px', bottom:'196px',position:'relative',zIndex:3, fontFamily:'Oswald',fontSize:'19px',backgroundColor:'white'}}>Buy</button>

    </div>

    <div data-aos = 'fade-up' className = 'originsd'>
<img style={{height:'260px', width:'260px', zIndex:3, backgroundColor:'transparent', position:'relative',top:'30px' }} src = {vont}/>
<Rating style = {{color:'white', position:'relative',right:'55px',top:'90px'}} name="half-rating-read" defaultValue={4.5} precision={0.5}  readOnly/>
<h5  style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'35px', fontWeight:'100', position:'relative',right:'29px', bottom:'60px'}} >Zentyr Vont JT</h5>
<h3 style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'35px', fontWeight:'100', position:'relative',right:'80px', bottom:'60px'}}>375$</h3>
<button className = 'shop'  onClick = {() => {setBuy(true);setLink4(true)}}  style = {{height:'50px',width:'110px',border:'solid 2px black',left:'75px', bottom:'146px',position:'relative',zIndex:3, fontFamily:'Oswald',fontSize:'19px',backgroundColor:'white'}}>Buy</button>

    </div>

    <div data-aos = 'fade-up' className = 'originse'>
<img style={{height:'320px', width:'320px', zIndex:3, backgroundColor:'transparent' }} src = {x}/>
<Rating style = {{color:'white', position:'relative',right:'50px',top:'50px'}} name="half-rating-read" defaultValue={3.5} precision={0.5} readOnly/>
<h5  style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'35px', fontWeight:'100', position:'relative',right:'29px', bottom:'100px'}} >Zentyr Banz JT</h5>
<h3 style={{zIndex:3, color:'white', fontFamily:'Oswald', fontSize:'35px', fontWeight:'100', position:'relative',right:'72px', bottom:'110px'}}>275$</h3>
<button className = 'shop' onClick = {() => {setBuy(true);setLink5(true)}}   style = {{height:'50px',width:'110px',border:'solid 2px black',left:'75px', bottom:'196px',position:'relative',zIndex:3, fontFamily:'Oswald',fontSize:'19px',backgroundColor:'white'}}>Buy</button>
<h6 style={{zIndex:3, fontFamily:'Oswald', fontSize:'15px', fontWeight:'100', position:'relative',right:'105px', bottom:'665px', color:'gold'}}>Customizeable </h6>

    </div>






   </div>

<div data-aos = 'flip-down' style = {{height:'0.1px',width:'16vw', border:'dotted 0.1px goldenrod', position:'absolute', top:'91vh',zIndex:1,opacity:'100%', transform:'rotate(-30deg)', left:'-5vw'}}/>


   <div style = {{position:'absolute',top:'0vh',width:'102vw',height:'85.3vh',left:'0vw',zIndex:1, objectFit:'fill', backgroundColor:'black',opacity:'90%'}}/>
   <div style = {{position:'absolute', top:'22vh', zIndex:2, fontFamily:'Oswald',left:'13vw', fontSize:'82px', color:'white', fontWeight:'400', width:'1400px', }}>
    <div className='type'>   <Typewriter  onInit = {(typewriter) => {typewriter.typeString("Top Drills, Jackets, Trends & Exclusive Zentyr Merches Down Below").pauseFor(1000).start()}}/></div>
   <Link to = 'ballz'  spy = {true} smooth = {true} offset = {-20} duration = {1100}> <motion.button className = 'shopx' >Explore</motion.button></Link>
   <div className = 'croc'>
   <Link to = 'ballzt'  spy = {true} smooth = {true} offset = {-20} duration = {1100}> <motion.button className = 'shopt' style={{ cursor: 'pointer',border:'solid 1px white', borderRadius: '10px', height: '75px', width: '300px',  zIndex: 2, fontFamily: 'Oswald', fontSize: '38px',border:'none',}} >Explore</motion.button></Link>
   </div>
</div>

<h1 data-aos = 'flip-up' className = 'fliped'>More To Be Released</h1>

<div className = 'twooth'>
<motion.img  src = {zenter} className = 'sauce' />
</div>
    </motion.div>
  
  
  
  
  <div style = {{display:secound? 'none': 'block'}}>
  <motion.div initial = {{opacity:0}} animate = {{opacity:1, rotate:80}} transition = {{duration:2, delay:5}} className = 'triangle'/>
   <motion.div initial = {{opacity:0}} animate = {{opacity:1, rotate:80}} transition = {{duration:2, delay:5}} className = 'triangle1'/>
<motion.p initial = {{opacity:0, y:-50}} animate = {{opacity:1, y:0}} transition = {{duration:2, delay:7.4, type:'tween'}} className = 'manifest'> <span style = {{color:'gold',}}>Introducing</span> Manifestations Of Top Quality Trends, Jackets, Perfumes & Exclusive Zentyr Drills For Extended Deals.</motion.p>
<motion.h1 className = 'unleashed' ><div style = {{color:'gold',fontSize:'45px'}}>{count}+ </div>Unleashed Zentyr Products</motion.h1>
<div initial = {{opacity:0}} animate = {{opacity:1}} transition = {{duration:2, delay:6}} className = 'circle' />
   <motion.img initial = {{opacity:0}} animate = {{opacity:1}} transition = {{duration:2, delay:7}} src = {cler} className = 'cler'/>
   <motion.img initial = {{opacity:0}} animate = {{opacity:1}} transition = {{duration:2, delay:7}} src = {goose} className = 'goose'/>
   <motion.img initial = {{opacity:0}} animate = {{opacity:1}} transition = {{duration:2, delay:7}} src = {sans} className = 'sans'/>
   <motion.button onClick = {() => {setPage(true); setSecound(true); setFirst(false); setThird(false)}} className = 'shop1' initial = {{opacity:0}} animate = {{opacity:1}} transition = {{duration:2, delay:7}} >Shop</motion.button>
    </div>

</div>

)}      

 </div>

)}</div>)}
</div>)}</div>)}
</div>)}
</div>)}


  </div>
)}
</div>)}
   
 

    </div>
  );
}

export default Home;
